import React from 'react';
import FaqTypes from './FaqTypes';
import faqData from './allFaq.json';

const Index = () => {
  return (
    <div className='py-6 bg-gray-50'>
    <div className='mx-2 sm:mx-6 xl:mx-10 my-2 md:my-10 xl:my-12'>
        <p className='text-xl my-2 font-bold '>
            Frequently Asked Questions
          </p>
        <div>
          {faqData.map((faq, index) => {
            return (
              <FaqTypes
                key={index}
                title={faq.title}
                questions={faq.questions}
                answers={faq.answers}
              />
            );
          })}
        </div>
      </div>
      </div>
  );
};

export default Index;
