import React, { useState } from 'react';

import Navbuttons from './Navbuttons';
import Home from './home/index';
import About from './about/index';
import Overview from './overview/index';
import Domains from './domains/index';
import Recuiters from './recuiters/index';
import Opportunities from './opportunities/index';
import Testimonials from './testimonials/index';
import FAQs from './faqs';
import allData from './allData.json';
import Footer from './Footer';

const Index = ({ setSelectedProgram, setCurrentScreen }) => {
  const [selectedNav, setSelectedNav] = useState('Home');

  const partnerData = allData[0];

  return (
    <div>
      <Navbuttons selectedNav={selectedNav} setSelectedNav={setSelectedNav} />
      <div>
        <div id='Home'>
          <Home companyData={partnerData.partnerLogo} />
        </div>
        <div id='Recruiters'>
          <Recuiters companyRecuiter={partnerData.companyRecuiter} />
        </div>
        <div id='Opportunities'>
          <Opportunities opportunitiesData={partnerData.opprtunitiesData} />
        </div>
        <div id='About'>
          <About />
        </div>
        <div id='Overview'>
          <Overview setSelectedNav={setSelectedNav} />
        </div>
        <div id='Domains'>
          <Domains
            companyData={partnerData.companyData}
            setCurrentScreen={setCurrentScreen}
            setSelectedProgram={setSelectedProgram}
          />
        </div>
        <div id='Testimonials'>
          <Testimonials testimonialData={partnerData.testimonialData} />
        </div>
        <div id='FAQs'>
          <FAQs />
        </div>
        <div className='md:mx-2 md:mx-6 lg:mx-10'>
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default Index;
