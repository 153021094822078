import React from 'react';
import { FaQuoteLeft } from 'react-icons/fa';

const Testimonial = ({ testimonial }) => {
  return (
    <div className='p-10 relative border-2 shadow-md rounded-lg h-1/2 lg:h-96'>
      <div className='font-medium text-lg'>
        <FaQuoteLeft />
        {testimonial.quote}
      </div>
        <img src={testimonial.pfp} alt='avatar' className='mt-6 inline-block h-12 w-12 rounded-full ring-2 ring-white'></img>
      <div className='font-thin my-2'>{testimonial.title}</div>
      </div>
  );
};

export default Testimonial;