import React, { useEffect } from 'react';
import Testimonial from './Testimonial';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const TestimonialSwiper = (props) => {
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--swiper-navigation-size',
      '20px'
    );
  }, []);
  return (
    <div>
      <Swiper
        slidesPerView={props.slidesPerView}
        spaceBetween={props.spaceBetween}
        navigation
        pagination={{ clickable: true }}
        style={{ padding: '50px 20px' }}
      >
        {props.testimonialData.map((testimonial, index) => {
          return (
            <SwiperSlide key={index}>
              <Testimonial testimonial={testimonial} />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default TestimonialSwiper;
