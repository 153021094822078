import React from 'react';
import { IoIosAddCircle, IoIosRemoveCircle } from 'react-icons/io';

const FAQQuestions = (props) => {
  return (
    <div
      className={`${
        !props.openFaq ? 'mb-6' : 'mt-6'
      } ' relative rounded-lg flex items-center  border  shadow-lg px-4 md:px-8 py-4 w-full grid grid-cols-4 z-50'`}
      key={props.index}
    >
      <p className='col-span-3 space-x-3 text-sm text-primary w-full'>
        <span className=''>{props.index + 1}.</span> <span>{props.ques}</span>
      </p>
      <button
        className='flex justify-end text-primary text-xl'
        onClick={() => {
          props.setFaq(props.index);
          props.setOpenFaq((prev) => !prev);
        }}
      >
        {!props.openFaq && <IoIosAddCircle />}
        {props.openFaq && props.index === props.faq && <IoIosRemoveCircle />}
        {props.openFaq && props.index !== props.faq && <IoIosAddCircle />}
      </button>
    </div>
  );
};

export default FAQQuestions;
