import React from 'react';

const Challenge = ({
  company,
  setShowFullDetails,
  setCurrentCompany,
  setCurrentScreen,
  setSelectedProgram,
}) => {
  return (
    <div
      id='reg'
      className='mt-10 relative border-2 shadow-md rounded-lg text-center h-3/5'
    >
      <div className='flex my-6 mx-3'>
        <div className=' font-semibold text-gray-600 border-2 text-center rounded-lg flex flex-col justify-evenly h-12 px-2 lg:px-20 '>
          {company.domain}
        </div>
        <a className='mx-2 lg:mx-auto' href={company.link}>
          <img className='h-12 ' src={company.logo} alt='logo' />
        </a>
      </div>
      <div className='my-5 sm:my-10	font-semibold text-xl text-primary'>
        {company.title}
      </div>
      <div className='text-white mx-auto bg-primary rounded-md py-3 text-center w-56 mt-8 lg:mt-10 cursor-pointer'>
        <button
          onClick={() => {
            setSelectedProgram(company);
            setCurrentScreen('PROGRAMDETAIL');
          }}
        >
          Register Now
        </button>
      </div>
      <div>
        {' '}
        <button
          onClick={() => {
            setCurrentCompany(company);
            setShowFullDetails(true);
          }}
          className='underline font-semibold text-sm mt-3 mb-6 text-primary cursor-pointer'
        >
          View More{' '}
        </button>
      </div>
    </div>
  );
};

export default Challenge;
