import React from 'react';
import VideoArea from './VideoArea';
import partnerLogo from '../allData.json';

const Index = ({ setCurrentScreen }) => {
  const partnerData = partnerLogo[0];

  return (
    <div className='mx-2 sm:mx-6 xl:mx-32'>
      <div className=' my-2 md:my-10 xl:my-12'>
        <div className='grid grid-cols-2 gap-4'>
          <div className='col-span-2 md:col-span-1'>
            <div className='text-3xl lg:text-4xl font-bold	font-sans '>
              <p>Solve Challenges to Create</p> <p>Impact {`&`} Get Hired</p>
            </div>
            <div className='mt-5 text-gray-700 font-medium text-xl'>
              Work with the Best Startups in Goa{' '}
              <p className='mt-5 text-gray-400 my-2'>Powered by </p>
            </div>
            <img
              alt='partnerlogo'
              src={partnerData.partnerLogo}
              className='w-28'
            />
            <div className='sticky top-32'>
              <div
                onClick={() => setCurrentScreen('PROGRAMS')}
                className='text-white bg-primary rounded-md py-3 text-center w-56 mt-8 lg:mt-6 cursor-pointer'
              >
                <a href='#reg'>
                  <p>Register Now</p>
                </a>
              </div>
            </div>
          </div>
          <div className='col-span-2 lg:col-span-1 '>
            <VideoArea
              videoUrl='https://www.youtube.com/embed/9a_TG8rmaBs'
              // videoTitle={props.videoTitle}
              // videoDescription={props.videoDescription}
              videoBackground='http://res.cloudinary.com/belong/image/upload/v1650367846/uploaded_resources/Video_1_thumbnail_jqce83.png'
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
