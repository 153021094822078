import React from 'react';
import ChallengeSwiper from './ChallengeSwiper';

const Index = ({ companyData, setSelectedProgram, setCurrentScreen }) => {
  return (
    <div className='mx-2 sm:mx-6 xl:mx-10'>
      <div className='my-2 md:my-10 xl:my-12'>
        <p className='text-xl my-2 font-bold '>Domains {`&`} Challenges</p>
        <p className='text-gray-500 font-medium'>
          Pick a company challenge and get guranteed job interviews
        </p>
        <div className=' hidden 2xl:block'>
          <ChallengeSwiper
            slidesPerView={3}
            spaceBetween={60}
            companyData={companyData}
            setCurrentScreen={setCurrentScreen}
            setSelectedProgram={setSelectedProgram}
          />
        </div>
        <div className=' hidden md:block 2xl:hidden'>
          <ChallengeSwiper
            slidesPerView={2}
            spaceBetween={60}
            companyData={companyData}
            setCurrentScreen={setCurrentScreen}
            setSelectedProgram={setSelectedProgram}
          />
        </div>
        <div className='px-4 md:hidden mx-auto'>
          <ChallengeSwiper
            slidesPerView={1}
            spaceBetween={10}
            companyData={companyData}
            setCurrentScreen={setCurrentScreen}
            setSelectedProgram={setSelectedProgram}
          />
        </div>
      </div>
    </div>
  );
};

export default Index;
