import React from 'react';
// import { RiNumber1, RiNumber2, RiNumber3, RiNumber4 } from 'react-icons/ri';
// import { FaRegStar } from 'react-icons/fa';
import FirstPart from './FirstPart';
import SecondPart from './SecondPart';

const index = () => {
  return (
    <div className='py-6 bg-gray-50'>
      <div className='mx-2 sm:mx-6 xl:mx-32'>
        <div className='my-2 md:my-10 xl:my-12'>
          <p className='text-xl my-2 font-bold '>
            How to get hired by your choice of startup in Goa?
          </p>
          <div>
            <p className='mt-5 text-gray-600 font-medium text-lg'>
              The employability program with {`Build3`} and its startup partners
              acts as a gateway to employment and enables you to unlock paid
              opportunities in 4 simple steps
            </p>
          </div>
          <FirstPart />
        </div>
        <div className='my-2 md:my-10 xl:my-12'>
          <p className='text-xl my-2 font-bold '>
            Why register to this program?
          </p>
          <div>
            <p className='mt-5 text-gray-600 font-medium text-lg'>
              The program enables you by providing you with an equal opportunity
              platform to bridge the gap between your education and your dream
              career.
            </p>
          </div>
          <SecondPart />
        </div>
      </div>
    </div>
  );
};

export default index;
