import React from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';

const TextInput = ({ label, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label
        className='w-full block mb-2 text-sm font-bold text-gray-600 text-left'
        htmlFor={props.id || props.name}
      >
        {label}
      </label>
      <input
        className='w-full px-3 py-2 text-sm leading-tight text-gray-600 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
        {...field}
        {...props}
      />
      {meta.touched && meta.error ? (
        <div className='text-sm text-red-500'>{meta.error}</div>
      ) : null}
    </>
  );
};

const SignUpForm = ({ setPassword, setCurrentScreen, setName, name }) => {
  return (
    <div className='mt-8'>
      <Formik
        initialValues={{
          name: name,
          password: '',
          confirmpassword: '',
        }}
        validationSchema={Yup.object({
          name: Yup.string()
            .max(100, 'Must be 100 characters or less')
            .required('Please enter your name'),
          password: Yup.string()
            .required('Please enter your password')
            .matches(
              /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
              'Password must contain at least 8 characters, one uppercase, one number and one special case character'
            ),
          confirmpassword: Yup.string()
            .required('Please confirm your password')
            .when('password', {
              is: (password) =>
                password && password.length > 0 ? true : false,
              then: Yup.string().oneOf(
                [Yup.ref('password')],
                "Password doesn't match"
              ),
            }),
        })}
        onSubmit={(values, { setSubmitting }) => {
          setPassword(values.password);
          setName(values.name);
          setCurrentScreen('SUCCESS');
        }}
      >
        {(formProps) => (
          <Form className=' bg-gray-50 px-8 pt-6 pb-8  rounded mx-8 '>
            <div className='mb-4'>
              <TextInput
                label='Name'
                name='name'
                type='text'
                placeholder='Name'
              />
            </div>

            <div className='mb-4'>
              <TextInput
                label='Password'
                name='password'
                type='password'
                placeholder='Password'
                autoComplete='new-password'
              />
            </div>
            <div className='mb-4'>
              <TextInput
                name='confirmpassword'
                label='Confirm Password'
                type='password'
                placeholder='Confirm Password'
              />
            </div>
            <div className='text-center flex justify-end'>
              <button
                className='bg-primary px-4 py-2 rounded text-white'
                type='submit'
              >
                Create Account
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default SignUpForm;
