import React from 'react';
import OpportunitySwiper from './OpportunitySwiper';

const Index = ({ opportunitiesData }) => {
  return (
    <div className='mx-2 sm:mx-6 xl:mx-10 my-2 md:my-10 xl:my-12'>
      <p className='text-center mt-5 text-gray-600 font-medium text-xl'>
        Featured Opportunities
      </p>
      <div className=' hidden 2xl:block'>
        <OpportunitySwiper
          opportunitiesData={opportunitiesData}
          slidesPerView={3}
          spaceBetween={60}
        />
      </div>
      <div className=' hidden md:block 2xl:hidden'>
        <OpportunitySwiper
          opportunitiesData={opportunitiesData}
          slidesPerView={2}
          spaceBetween={60}
        />
      </div>
      <div className='px-4 md:hidden mx-auto'>
        <OpportunitySwiper
          opportunitiesData={opportunitiesData}
          slidesPerView={1}
          spaceBetween={10}
        />
      </div>
    </div>
  );
};

export default Index;
