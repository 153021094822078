import React from 'react';
import { Link } from 'react-router-dom';
import {
  AiOutlineCopyrightCircle,
  AiOutlineInstagram,
  AiOutlineTwitter,
} from 'react-icons/ai';
import { GrFacebookOption } from 'react-icons/gr';
import { LinkedinFollowCompany } from 'react-social-plugins';

function Footer() {
  return (
    <div className='bg-white border border-b-0 border-l-0 border-r-0 mt-32'>
      <div className='py-2 md:py-8 w-full px-6 md:px-12 flex flex-col md:flex-row justify-between'>
        <div className='w-full mb-8 md:mb-0 mr-0 md:mr-10'>
          <p className='font-bold text-primary text-xl text-center md:text-left'>
            Belong
          </p>
          <p className='font-bold text-gray-500 text-lg mt-2 text-center md:text-left'>
            An Ingenious Faces Marketplace
          </p>
          <p className='font-bold text-gray-400 text-center md:text-left text-xs mt-4 mb-4 md:mb-0'>
            We facilitate a seamless ecosystem of opportunities for young
            talent,global employers and universities across the globe.
          </p>
        </div>
        <div className='flex flex-row justify-between w-full mb-8 md:mb-0'>
          <div className='text-gray-400 text-sm font-bold flex flex-col text-center'>
            <a
              className='mb-2'
              href='https://belong.education/about-us.html'
              target='_blank'
              rel='noreferrer'
            >
              About Us
            </a>
            <a
              className='mb-2'
              href='https://belong.education/contact.html'
              target='_blank'
              rel='noreferrer'
            >
              Contact Us
            </a>
            <a
              href='https://ingeniousfaces.com/'
              target='_blank'
              rel='noreferrer'
            >
              Ingenious Faces
            </a>
          </div>
          <div className='text-gray-400 text-sm font-bold text-center flex flex-col'>
            <a
              className='mb-2'
              href='https://employers.belong.education/'
              target='_blank'
              rel='noreferrer'
            >
              Employers
            </a>
            <a
              className='mb-2'
              href='https://universities.belong.education/'
              target='_blank'
              rel='noreferrer'
            >
              Universities
            </a>
            <Link to='/signup'>Sign up</Link>
          </div>
          <div className='text-gray-400 text-sm font-bold text-center flex flex-col'>
            <a
              className='mb-2'
              href='https://dashboard.belong.education/privacy-policy'
              target='_blank'
              rel='noreferrer'
            >
              Privacy
            </a>
            <a
              className='mb-2'
              href='https://dashboard.belong.education/privacy-policy'
              target='_blank'
              rel='noreferrer'
            >
              Terms
            </a>
          </div>
        </div>
      </div>
      <div className='py-4 px-12 flex flex-col md:flex-row justify-between border border-b-0 border-l-0 border-r-0'>
        <div className='flex flex-row items-center justify-center md:justify-start'>
          <p className='font-bold text-gray-400 text-xs mb-4 mt-0 md:mt-3'>
            <span className='inline-block mr-1'>
              <AiOutlineCopyrightCircle />
            </span>
            Belong. All Rights Reserved. 2020
          </p>
        </div>
        <div className='flex flex-col md:flex-row text-gray-400 font-bold text-xs items-center'>
          <div className='flex  mb-4 mt-0 md:mt-3 mr-0 md:mr-0'>
            <a
              className='mr-6 text-3xl'
              href='https://instagram.com/ingeniousfaces?utm_medium=copy_link'
              target='_blank'
              rel='noreferrer'
            >
              <AiOutlineInstagram />
            </a>
            <a
              className='mr-6 text-3xl'
              href='https://www.facebook.com/IngeniousFaces1'
              target='_blank'
              rel='noreferrer'
            >
              <GrFacebookOption />
            </a>
            <a
              className='mr-6 text-3xl'
              href='https://twitter.com/IngeniousFaces'
              target='_blank'
              rel='noreferrer'
            >
              <AiOutlineTwitter />
            </a>
          </div>
          <div className='border border-t-0 border-r-0 rounded'>
            <LinkedinFollowCompany
              companyId={14375686}
              counter='right'
              lang='en_US'
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
