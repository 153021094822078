import React from 'react';
import TestimonialSwiper from './TestimonialSwiper';

const Index = ({ testimonialData }) => {
  return (
    <div className='mx-2 sm:mx-6 xl:mx-10 my-2 md:my-10 xl:my-12'>
        <p className='text-xl my-2 font-bold '>
          Success Stories
        </p>
        <div className=' hidden 2xl:block'>
          <TestimonialSwiper
            slidesPerView={3}
            spaceBetween={60}
            testimonialData={testimonialData}
          />
        </div>
        <div className=' hidden md:block 2xl:hidden'>
          <TestimonialSwiper
            slidesPerView={2}
            spaceBetween={60}
            testimonialData={testimonialData}
          />
        </div>
        <div className='px-4 md:hidden mx-auto'>
          <TestimonialSwiper
            slidesPerView={1}
            spaceBetween={10}
            testimonialData={testimonialData}
          />
        </div>
      </div>
  );
};

export default Index;
