import React from 'react';

const FAQAnswers = (props) => {
  return (
    <div className=' bg-gray-50 mx-3 md:mx-8 px-3 py-4 md:py-8 md:px-6 shadow rounded z-10'>
      {props.answers.map(
        (ans, i) =>
          ans.sectionNumber === props.faq && (
            <div key={i}>
              {ans.contentType === 'Text' && (
                <p className='text-gray-400 text-xs my-2'>
                  {ans.contentToPublish}
                </p>
              )}
              {ans.contentType === 'List' && (
                <li className='text-primary text-xs list-disc px-3 md:px-6'>
                  <span className='text-gray-400'>{ans.contentToPublish}</span>
                </li>
              )}
            </div>
          )
      )}
    </div>
  );
};

export default FAQAnswers;
