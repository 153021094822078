import React from 'react';
import Slider from './Slider';
const index = () => {
  return (
    <div className='py-6 bg-gray-50'>
      <div className='mx-2 sm:mx-6 xl:mx-10 my-2 md:my-10 xl:my-12' >
        <Slider />
      </div>
    </div>
  );
};

export default index;
