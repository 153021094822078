import React, { useState } from 'react';
import ProgramDetails from './ProgramDetails';
import Signup from './signUp/Signup';
import SuccessMessage from './SuccessMessage';

const Index = ({ currentScreen, selectedProgram, setCurrentScreen }) => {
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');
  const [userExists, setUserExists] = useState(false);
  const [name, setName] = useState('');

  return (
    <div>
      {currentScreen === 'PROGRAMDETAIL' && (
        <ProgramDetails
          email={email}
          setEmail={setEmail}
          phone={phone}
          setPhone={setPhone}
          name={name}
          setName={setName}
          userExists={userExists}
          setUserExists={setUserExists}
          selectedProgram={selectedProgram}
          setCurrentScreen={setCurrentScreen}
        />
      )}

      {currentScreen === 'SIGNUP' && (
        <Signup
          name={name}
          setPassword={setPassword}
          setCurrentScreen={setCurrentScreen}
          setName={setName}
        />
      )}

      {currentScreen === 'SUCCESS' && (
        <SuccessMessage
          userExists={userExists}
          email={email}
          phone={phone}
          name={name}
          password={password}
          selectedProgram={selectedProgram}
        />
      )}
    </div>
  );
};

export default Index;
