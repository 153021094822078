import React, { useState } from 'react';
import HomePage from './homepage';
import LeftSection from './LeftSection';
import Register from './register';

const Index = () => {
  const [currentScreen, setCurrentScreen] = useState('LANDINGPAGE');
  const [selectedProgram, setSelectedProgram] = useState({});

  return (
    <div>
      {currentScreen === 'LANDINGPAGE' ? (
        <HomePage
          setCurrentScreen={setCurrentScreen}
          setSelectedProgram={setSelectedProgram}
        />
      ) : (
        <div className='grid grid-cols-1 md:grid-cols-2'>
          <div className='bg-gray-50 md:h-screen md:sticky top-0 left-0 '>
            <LeftSection />
          </div>
          <div className='mb-8'>
            <Register
              currentScreen={currentScreen}
              selectedProgram={selectedProgram}
              setCurrentScreen={setCurrentScreen}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Index;
