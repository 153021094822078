import React from 'react';
import VideoArea from './VideoArea';
import { FaRegStar } from 'react-icons/fa';
const SecondPart = () => {
  return (
    <div className=' my-6'>
      <div className='mx-auto w-3/4 md:w-1/2'>
        <VideoArea
          videoUrl='https://www.youtube.com/embed/BMut4bgrIEs'
          // videoTitle={props.videoTitle}
          // videoDescription={props.videoDescription}
          videoBackground='http://res.cloudinary.com/belong/image/upload/v1650367874/uploaded_resources/Video_2_thumbnail_busoa0.png'
        />
      </div>
      <div className='grid grid-cols-3 gap-6 my-10  '>
        <div className='text-center sm:col-span-1 col-span-3'>
          <FaRegStar className='text-4xl mx-auto text-primary' />
          <span className='font-bold text-clip'>
            Experience Start Up Culture {`&`} Accelerated Learning Curve
          </span>
        </div>
        <div className='text-center sm:col-span-1 col-span-3'>
          <FaRegStar className='text-4xl mx-auto text-primary' />
          <span className='font-bold'>
            Work With Impact Focused Startups Based Out Of Goa
          </span>
        </div>
        <div className='text-center sm:col-span-1 col-span-3'>
          <FaRegStar className='text-4xl mx-auto text-primary' />
          <span className='font-bold'>
            Access The Community Of Industry Experts {`&`} Mentors
          </span>
        </div>
      </div>
    </div>
  );
};

export default SecondPart;
