import React from 'react';
import { ImCross } from 'react-icons/im';

const FullDetails = ({ setShowFullDetails, opportunity }) => {
  return (
    <div className='justify-center items-center overflow-scroll fixed inset-0 outline-none focus:outline-none bg-gray-50 bg-opacity-50 z-50'>
      <div className='w-full md:w-3/4 my- mx-auto'>
        <div className='border-0 p-8 rounded-lg shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none'>
          <div className='flex justify-center pb-3'>
            <div className='flex items-center justify-start w-full justify-center my-10 border-2 bg-gray-100'>
              <div className='flex items-center justify-between h-24  '>
                <img
                  className='h-8 sm:h-10 md:h-12 w-24 sm:w-auto py-2 pr-4 ml-4 md:ml-8 border-gray-500 border-r-2'
                  alt='cactus-logo'
                  src={opportunity.logo}
                ></img>
                <div className='flex flex-col px-4'>
                  <span className='text-sm md:text-base font-semibold'>
                    {opportunity.title}
                  </span>
                  <p className='text-gray-700 text-sm md:text-base'>
                    {opportunity.domain}
                  </p>
                </div>
              </div>
            </div>
            <div className='my-3 ml-4'>
              <button onClick={() => setShowFullDetails(false)}>
                <ImCross className='text-gray-600' />
              </button>
            </div>
          </div>

          <div className=''>
            <div className=' font-semibold text-sm'>About the Opportunity</div>

            {opportunity.about.map((about, index) => {
              return (
                <p
                  key={index}
                  className=' font-semibold text-sm my-3 text-gray-500'
                >
                  {about}
                </p>
              );
            })}
            <div className='font-semibold text-sm'>
              <div className='my-3 text-gray-600'>{opportunity.duties}</div>
              <div className='my-3 text-gray-500'>{opportunity.des}</div>
            </div>
            <div className='my-4 text-gray-500 text-sm text-left'>
              {opportunity.keyPoints.map((point, index) => {
                return (
                  <li
                    className='font-semibold text-sm my-3 text-gray-500'
                    key={index}
                  >
                    {point}
                  </li>
                );
              })}
            </div>
            <div>
              <div className='font-semibold text-sm'>
                <div className='my-3 text-gray-600'>{opportunity.skills}</div>
              </div>
              <div className='my-4 text-gray-500 text-sm text-left'>
                {opportunity.skillSets.map((point, index) => {
                  return (
                    <li
                      className='font-semibold text-sm my-3 text-gray-500'
                      key={index}
                    >
                      {point}
                    </li>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullDetails;
