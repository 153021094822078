import React, { useState } from 'react';
import QAs from './QAs';

import { FaAngleDown, FaAngleUp } from 'react-icons/fa';

const FaqTypes = (props) => {
  const [showQuestion, setShowQuestion] = useState(false);
  return (
    <div>
      <div className='m-1 sm:m-4 py-5 px-4 md:px-10 xl:px-16 border-xl border-gray-200 border-2'>
        <div
          className='flex justify-between'
          onClick={() => {
            setShowQuestion(!showQuestion);
          }}
        >
          <div className='font-semibold text-gray-500 text-lg'>
            {props.title}
          </div>
          <div>{showQuestion ? <FaAngleUp /> : <FaAngleDown />}</div>
        </div>
        <div>
          {' '}
          {showQuestion && (
            <QAs questions={props.questions} answers={props.answers} />
          )}
        </div>
      </div>
    </div>
  );
};

export default FaqTypes;
