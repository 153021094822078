import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Start from './components';

const Routes = () => {
  return (
    <Router>
      <Switch>
        <Route path='/' exact component={Start} />
        <Route path='/build3' exact component={Start} />
      </Switch>
    </Router>
  );
};

export default Routes;