import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 5,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
  },
};

const images = [
  'http://res.cloudinary.com/belong/image/upload/v1649396736/uploaded_resources/that_lifestyle_coach_uqccs7.png',
  'https://res.cloudinary.com/belong/image/upload/v1648469815/uploaded_resources/Ruby_s_Coffee_uwa6zz.jpg',
  'https://res.cloudinary.com/belong/image/upload/v1649396257/uploaded_resources/OneBoard_Logo_av2rfb.png',
  'https://res.cloudinary.com/belong/image/upload/v1648469803/uploaded_resources/makers_Asylum_logo_hfpgqc.png',
  'https://res.cloudinary.com/belong/image/upload/v1648469796/uploaded_resources/Build3_logo_bze7fs.png',
  'https://res.cloudinary.com/belong/image/upload/v1648469791/uploaded_resources/Blive_logo_krpnhx.png',
];
const Simple = ({ deviceType }) => {
  return (
    <Carousel
      swipeable={true}
      draggable={true}
      showDots={false}
      responsive={responsive}
      // ssr={true} // means to render carousel on server-side.
      infinite={true}
      autoPlay={true}
      autoPlaySpeed={2000}
      keyBoardControl={false}
      // customTransition='all .5'
      // transitionDuration={4000}
      containerClass='carousel-container'
      arrows={false}
      deviceType={deviceType}
      // dotListClass='custom-dot-list-style'
      itemClass='carousel-item-padding-40-px'
    >
      {images.slice(0, 10).map((image, index) => {
        return (
          <div key={index}>
            <img
              src={image}
              alt=''
              style={{ width: '150px', height: '80px', textAlign: 'center' }}
            ></img>
          </div>
        );
      })}
    </Carousel>
  );
};

export default Simple;
