import React, { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../API_LINKS';

const SuccessMessage = (props) => {
  const programId = props.selectedProgram.id;
  const date = new Date();
  const offset = date.getTimezoneOffset();

  useEffect(() => {
    const data = {
      name: props.name,
      userExists: props.userExists,
      email: props.email,
      phone: props.phone,
      password: props.password,
      programId: programId,
      utcOffset: offset,
      partnerId: '610a7ca8d3c51a1458d005ce',
      enrollmentType: 'Careers Page',
    };
    axios
      .post(API_URL + '/b2c/createAndEnrollUser', data)
      .then((res) => {
        //show success message
      })
      .catch((res) => {
        if (props.userExists) {
          //select program
        } else {
          //signup
        }
      });
  }, [
    props.userExists,
    props.email,
    props.phone,
    props.password,
    programId,
    props.name,
    offset,
  ]);

  return (
    <div>
      <div className='text-primary text-xl font-semibold my-4 p-8 mt-6 md:mt-12 '>
        Registration Successful
      </div>
      <div className='my-4 p-8 text-lg'>
        <p>You have successfully enrolled in {props.selectedProgram.title}.</p>{' '}
        <p>Your Program will start from 2nd May </p>
        <p>You will be receiving email with further details</p>
      </div>
    </div>
  );
};

export default SuccessMessage;
