import React from 'react';

const index = ({ setSelectedNav }) => {
  return (
    <div className='mx-2 sm:mx-6 xl:mx-10 my-2 md:my-10 xl:my-12'>
      <div className='border-2 bg-primary m-12 w-20 mx-auto rounded-md border-primary' />
      <div className='text-center font-bold mb-3 md:mb-12 lg:mb-20 text-xl'>
        Overview
      </div>
      <div className=' border-2 shadow-lg text-center grid grid-cols-1 md:grid-cols-5 gap-3 my-10 py-10 px-3 '>
        <div className='text-primary font-semibold  '>
          <span className='text-gray-500 '>Format</span> <br /> Online
        </div>
        <div className='text-primary font-semibold  '>
          <span className='text-gray-500 '>Eligibility Criteria</span> <br />{' '}
          Graduates
        </div>
        <div className='text-primary font-semibold '>
          <span className='text-gray-500 '>Domains</span> <br /> 5
        </div>
        <div className='text-primary font-semibold '>
          <span className='text-gray-500 '>Company Recuiters</span> <br /> 50+
        </div>
        <div className='text-primary font-semibold '>
          <span className='text-gray-500 '>Success Stories</span> <br /> 500+
        </div>
      </div>
    </div>
  );
};

export default index;
