import React, { useState } from 'react';
import FAQAnswers from './FAQAnswers';
import FAQQuestions from './FAQQuestions';

const QAs = (props) => {
  const [faq, setFaq] = useState();
  const [openFaq, setOpenFaq] = useState(false);
  return (
    <div className='bg-white py-8 md:py-12 rounded-xl font-semibold'>
      <div>
        {props.questions.map((ques, index) => {
          return (
            <div key={index}>
              <FAQQuestions
                openFaq={openFaq}
                index={index}
                ques={ques}
                setFaq={setFaq}
                setOpenFaq={setOpenFaq}
                faq={faq}
              />
              {openFaq && faq === index && (
                <FAQAnswers answers={props.answers} faq={faq} />
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default QAs;
