import React, { useState } from 'react';

import { FaAngleLeft } from 'react-icons/fa';
import CompanyFullDetail from './CompanyFullDetail';
import BasicDetails from './BasicDetails';
import Payment from './payment';

const ProgramDetails = (props) => {
  const [showPayment, setShowPayment] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [savedOrderId, setSavedOrderId] = useState();
  return (
    <div>
      <div className='flex justify-between p-8 border-b-2'>
        <div
          className='flex items-center text-gray-600 cursor-pointer'
          onClick={() => {
            props.setCurrentScreen('LANDINGPAGE');
          }}
        >
          <FaAngleLeft />
          <button className='ml-2'> Back</button>
        </div>
        <div className='text-xs text-gray-400'>
          <div>STEP 01/03</div>
        </div>
      </div>
      <CompanyFullDetail selectedProgram={props.selectedProgram} />

      <div className='flex justify-around mt-4 mx-8'>
        <button
          className='bg-primary w-full  px-4 py-2 text-gray-50 rounded'
          onClick={() => {
            setShowModal(true);
          }}
        >
          REGISTER NOW FOR RS. 200
        </button>
      </div>
      {showModal && (
        <BasicDetails
          setShowModal={setShowModal}
          setShowPayment={setShowPayment}
          checkedProgram={props.selectedProgram.id}
          setCurrentScreen={props.setCurrentScreen}
          email={props.email}
          setEmail={props.setEmail}
          phone={props.phone}
          setPhone={props.setPhone}
          name={props.name}
          setName={props.setName}
          userExists={props.userExists}
          setUserExists={props.setUserExists}
          setPaymentLoading={setPaymentLoading}
          setSavedOrderId={setSavedOrderId}
        />
      )}

      {showPayment && (
        <Payment
          email={props.email}
          phone={props.phone}
          currency='INR'
          amount='200'
          setShowModal={setShowModal}
          userExists={props.userExists}
          checkedProgram={props.selectedProgram.id}
          setCurrentScreen={props.setCurrentScreen}
          setPaymentLoading={setPaymentLoading}
          setShowPayment={setShowPayment}
          savedOrderId={savedOrderId}
        />
      )}
      {paymentLoading && (
        <div className='justify-center items-center flex overflow-x-auto overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-gray-50 bg-opacity-80 z-50'>
          <div className=' w-screen my-6 mx-auto '>
            <div className='border-0 p-8 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none'>
              {' '}
              Loading{' '}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProgramDetails;
