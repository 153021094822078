import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ImCross } from 'react-icons/im';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { API_URL } from '../../API_LINKS';

const BasicDetails = ({
  setShowModal,
  setShowPayment,
  setCurrentScreen,
  email,
  setEmail,
  phone,
  setPhone,
  name,
  setName,
  checkedProgram,
  userExists,
  setUserExists,
  setPaymentLoading,
  setSavedOrderId,
}) => {
  const [loading, setLoading] = useState(false);
  const [paymentMade, setPaymentMade] = useState(false);
  const [initiate, setInitiate] = useState(false);
  const [emailAndPhoneValidation, setEmailAndPhoneValidation] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  useEffect(() => {
    function handleResponse(userExists, paymentMade) {
      if (userExists && paymentMade) {
        setCurrentScreen('SUCCESS');
      } else if (paymentMade) {
        setCurrentScreen('SIGNUP');
      } else if (userExists) {
        setShowModal(false);
        setShowPayment(true);
        setUserExists(true);
        setPaymentLoading(true);
      } else {
        setShowModal(false);
        setShowPayment(true);
        setPaymentLoading(true);
      }
    }
    if (initiate) {
      handleResponse(userExists, paymentMade);
    }
  }, [
    initiate,
    userExists,
    paymentMade,
    setCurrentScreen,
    setShowModal,
    setShowPayment,
    setUserExists,
    setPaymentLoading,
  ]);

  async function getUserByEmail(email) {
    const res = await axios.get(API_URL + '/user', {
      params: { email: email },
    });
    return res;
  }

  const updatePhone = (phone) => {
    phone = phone.replace(/[^a-zA-Z0-9]/g, '');
    if (phone.match(/^(\d{10}|\d{11}|\d{12}|\d{13})$/)) {
      setEmailAndPhoneValidation(true);
      setPhone(phone);
    } else {
      setEmailAndPhoneValidation(false);
      setErrorMessage('Enter valid phone number');
    }
  };

  useEffect(() => {
    if (phone.match(/^(\d{10}|\d{11}|\d{12}|\d{13})$/)) {
      updatePhone(phone);
    }
  });

  async function handleSendCodeClick(event) {
    setLoading(true);
    event.preventDefault();
    if (emailAndPhoneValidation) {
      const body = {
        orderType: 'Careers Page',
        name: name,
        email: email,
        phone: phone,
        programId: checkedProgram,
      };
      axios
        .post(API_URL + '/order/order-metadata', body)
        .then(async function (response) {
          setSavedOrderId(response.data._id);
          const user = await getUserByEmail(email);
          response.data.orderId && setPaymentMade(true);
          if (user.data !== 'No user') {
            setUserExists(true);
          }
          setInitiate(true);
          // setCurrentScreen('DEADLINE');
          //Cases
          // 1. Payment made and user also userExists :
          // 2. Payment made but user does not userExist
          // 3. Payment not made and user userExists
          // 4. payment not made and user does not exist
        })
        .catch(function (error) {
          setLoading(false);
        });
    } else {
      setLoading(false);
      setErrorMessage('Enter valid phone number');
    }
  }

  return (
    <div
      className={`justify-center items-center flex overflow-x-auto overflow-y-auto fixed inset-0 outline-none focus:outline-none bg-gray-50 bg-opacity-50 z-50 ${
        loading && 'bg-white bg-opacity-80'
      }`}
    >
      <div className=' w-full md:w-2/5 my-6 mx-auto '>
        <div className='border-0 p-8 rounded-lg shadow-lg  flex flex-col w-full bg-white outline-none focus:outline-none'>
          <div className='flex justify-between pb-3 border-b-2'>
            <p className='text-primary'>
              Please fill out the details to proceed
            </p>
            <button onClick={() => setShowModal(false)}>
              <ImCross className='text-gray-600' />
            </button>
          </div>

          <div>
            <form
              className='px-8 pt-6 pb-1 mb-4 bg-white rounded'
              onSubmit={handleSendCodeClick}
            >
              <div className='mb-4'>
                <label
                  className='block mb-2 text-sm font-bold text-gray-700 text-left'
                  htmlFor='nmae'
                >
                  Name
                </label>

                <input
                  className='w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
                  value={name}
                  type='text'
                  onChange={(event) => setName(event.target.value)}
                  placeholder='Enter Your Name...'
                  required
                />
              </div>
              <div className='mb-4'>
                <label
                  className='block mb-2 text-sm font-bold text-gray-700 text-left'
                  htmlFor='email'
                >
                  Email
                </label>

                <input
                  className='w-full px-3 py-2 text-sm leading-tight text-gray-700 border rounded shadow appearance-none focus:outline-none focus:shadow-outline'
                  value={email}
                  type='email'
                  onChange={(event) => setEmail(event.target.value)}
                  placeholder='Enter Email Address...'
                  required
                />
              </div>
              <div className='mb-4'>
                <label
                  className='block mb-2 text-sm font-bold text-gray-700 text-left'
                  htmlFor='email'
                >
                  Contact Number
                </label>
                <div className='w-full relative'>
                  <PhoneInput
                    value={phone}
                    onBlur={(event) => updatePhone(event.target.value)}
                    preferredCountries={['in', 'za']}
                    placeholder='Phone No.'
                    country={'in'}
                    inputStyle={{ width: '100%' }}
                  />
                  {!emailAndPhoneValidation && (
                    <p className='text-xs text-red-500'>{errorMessage}</p>
                  )}
                </div>
              </div>
              <div className='my-6 items-center flex justify-end'>
                <div
                  className='text-gray-400 text-xs mr-4 cursor-pointer'
                  onClick={() => {
                    if (!loading) {
                      setShowModal(false);
                    }
                  }}
                >
                  Take me back
                </div>
                <button
                  className='bg-primary px-4 py-2 rounded text-white'
                  type='submit'
                >
                  {loading ? 'Loading...' : 'Continue'}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDetails;
