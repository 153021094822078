import React, { useEffect, useState } from 'react';
import Opportunity from './Opportunity';
import FullDetails from './FullDetails';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const OpportunitySwiper = (props) => {
  const [showFullDetails, setShowFullDetails] = useState(false);
  const [currentOpportunity, setCurrentOpportunity] = useState(null);
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--swiper-navigation-size',
      '20px'
    );
  }, []);
  return (
    <div>
      {showFullDetails && (
        <FullDetails
          opportunity={currentOpportunity}
          setShowFullDetails={setShowFullDetails}
        />
      )}
      <Swiper
        slidesPerView={props.slidesPerView}
        spaceBetween={props.spaceBetween}
        navigation
        pagination={{ clickable: true }}
        style={{ padding: '50px 20px' }}
      >
        {props.opportunitiesData.map((opportunity, index) => {
          return (
            <SwiperSlide key={index}>
              <Opportunity
                opportunity={opportunity}
                setShowFullDetails={setShowFullDetails}
                setCurrentOpportunity={setCurrentOpportunity}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default OpportunitySwiper;
