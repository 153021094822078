import React from 'react';
import { ImCross } from 'react-icons/im';

const FullDetails = ({ setShowFullDetails, company }) => {
  return (
    <div className='justify-center items-center overflow-scroll fixed inset-0 outline-none focus:outline-none bg-gray-50 bg-opacity-50 z-50'>
      <div className='w-full md:w-3/4 my- mx-auto'>
        <div className='border-0 p-8 rounded-lg shadow-lg flex flex-col w-full bg-white outline-none focus:outline-none'>
          <div className='flex justify-center pb-3'>
            <div className='flex items-center justify-start w-full justify-center my-10 border-2 bg-gray-100'>
              <a href={company.link}>
                <div className='flex items-center justify-between h-24  '>
                  <img
                    className='h-8 sm:h-10 md:h-12 w-24 sm:w-auto py-2 pr-4 ml-4 md:ml-8 border-gray-500 border-r-2'
                    alt='cactus-logo'
                    src={company.logo}
                  />
                  <div className='flex flex-col px-4'>
                    <span className='text-sm md:text-base font-semibold'>
                      {company.title}
                    </span>
                    <p className='text-gray-700 text-sm md:text-base'>
                      {company.domain}
                    </p>
                  </div>
                </div>
              </a>
            </div>
            <div className='my-3 ml-4'>
              <button onClick={() => setShowFullDetails(false)}>
                <ImCross className='text-gray-600' />
              </button>
            </div>
          </div>

          <div className=' font-semibold text-sm'>Challenge Details</div>
          <p className=' font-semibold text-sm my-3 text-gray-700'>
            {company.about}
          </p>

          <div className='font-semibold text-sm'>
            During this Program :-
            {company.details.map((details, index) => {
              return (
                <div
                  className='font-semibold text-sm my-3 text-gray-700'
                  key={index}
                >
                  {`• `}
                  {details}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FullDetails;
