import React from 'react';
// import ImageCard from './ImageCard';
const FirstPart = () => {
  return (
    <div>
      <div className=''>
        <div className='flex flex-col lg:flex-row items-center my-6'>
          <img
            className='h-20 w-24 lg:w-20 lg:h-16'
            src='https://res.cloudinary.com/belong/image/upload/v1649318058/uploaded_resources/01_uscp2l.png'
            alt='icon-1'
          />
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Showcase Your Entrepreneurial Spirit</p>
            <p className='text-gray-600 font-medium text-lg'>
              Demonstrate Entrepreneurial Mindset And The Passion To Create
              Change
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row items-center my-6'>
          <img
            className='h-20 w-24 lg:w-20 lg:h-16'
            src='https://res.cloudinary.com/belong/image/upload/v1649318063/uploaded_resources/02_j8u54w.png'
            alt='icon-1'
          />
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Pick A Domain {`&`} Solve a Challenge </p>
            <p className='text-gray-600 font-medium text-lg'>
              Showcase Your Skills And Complete The Program Requirement
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row items-center my-6'>
          <img
            className='h-20 w-24 lg:w-20 lg:h-16'
            src='https://res.cloudinary.com/belong/image/upload/v1649318067/uploaded_resources/03_nlx4p6.png'
            alt='icon-1'
          />
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Pitch To Company Recuiter</p>
            <p className='text-gray-600 font-medium text-lg'>
              Pitch Your Solution And Earn Your Employability Certificate
            </p>
          </div>
        </div>
        <div className='flex flex-col lg:flex-row items-center my-6'>
          <img
            className='h-20 w-24 lg:w-20 lg:h-16'
            src='https://res.cloudinary.com/belong/image/upload/v1649318072/uploaded_resources/04_zsykbg.png'
            alt='icon-1'
          />
          <div className='font-bold text-xl text-center lg:text-left'>
            <p>Land An opportunity</p>
            <p className='text-gray-600 font-medium text-lg'>
              Guaranteed Paid Work Opportunities With Build3 And Partners
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstPart;
