import React, { useState } from 'react';
import { FaPlayCircle } from 'react-icons/fa';
import ReactPlayer from 'react-player';

const VideoArea = (props) => {
  const [startVideo, setStartVideo] = useState(false);
  return (
    <div className='w-full'>
      <div
        className={`h-3/5 md:h-96 rounded-xl border-2 relative bg-contain bg-no-repeat `}
        style={{
          backgroundImage: !startVideo && `url(${props.videoBackground})`,
          width: '100%',
          backgroundPosition: 'center',
        }}
      >
        {startVideo &&
          (props.type ? (
            <iframe
              width='100%'
              height='100%'
              src={props.videoUrl}
              frameborder='0'
              allowfullscreen
              title='Video'
            ></iframe>
          ) : (
            <ReactPlayer
              url={props.videoUrl}
              controls={true}
              width='100%'
              height='100%'
            />
          ))}
        {startVideo ? (
          <button
            className='absolute right-0 -top-6 font-bold text-gray-50 bg-primary px-3 cursor-pointer'
            onClick={() => setStartVideo(false)}
          >
            Close
          </button>
        ) : null}
        {!startVideo && (
          <div className='text-white bottom-0 left-16 p-4'>
            <FaPlayCircle
              className='text-primary text-4xl cursor-pointer'
              onClick={() => setStartVideo(true)}
            />
            {/* <p className='text-lg mb-2'>{props.videoTitle}</p>
            <br />
            <p className='mt-3 md:mt-6'>{props.videoDescription}</p> */}
          </div>
        )}
      </div>
    </div>
  );
};

export default VideoArea;
