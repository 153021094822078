import React from 'react';

const LeftSection = () => {
  return (
    <div>
      <img
        src='https://res.cloudinary.com/belong/image/upload/v1650432294/uploaded_resources/Build3_Selection_Payment_k10bva.png'
        alt='Logo'
        className='xl:h-screen object-cover'
      />
      {/* <div className='flex mt-10'>
        <img
          src='http://res.cloudinary.com/belong/image/upload/v1638779053/uploaded_resources/image_38_rp9n9y.png'
          alt='Logo'
          className='h-12 md:h-16 object-cover'
        />
        <div className='border-r mx-4 border-black'></div>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
          alt='Logo'
          className='h-12 md:h-16 object-cover'
        />
      </div>
      <div className='flex justify-around'>
        <img
          src='http://res.cloudinary.com/belong/image/upload/v1641288004/uploaded_resources/Step_1-_Start_your_Journey_Real-_World_Projects___21st_century_Upskilling_tasks__4_-removebg_zcf5l7.png'
          alt='Logo'
          className='w-full mt-16 md:mt-32 object-cover'
        />
      </div>
      <div className='mt-16 p-8 w-full bg-primary-light rounded-lg'>
        <p className='font-semibold text-gray-700 text-center'>
          UNLOCK EARNING OPPORTUNITIES
        </p>
      </div> */}
    </div>
  );
};

export default LeftSection;
