import React, { useEffect, useState } from 'react';
import Challenge from './Challenge';
import FullDetails from './FullDetails';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Pagination, Navigation, Scrollbar } from 'swiper';
import 'swiper/swiper-bundle.min.css';

SwiperCore.use([Navigation, Pagination, Scrollbar]);

const ChallengeSwiper = (props) => {
  const [showFullDetails, setShowFullDetails] = useState(false);
  const [currentCompany, setCurrentCompany] = useState(null);
  useEffect(() => {
    document.documentElement.style.setProperty(
      '--swiper-navigation-size',
      '20px'
    );
  }, []);
  return (
    <div>
      {showFullDetails && (
        <FullDetails
          company={currentCompany}
          setShowFullDetails={setShowFullDetails}
        />
      )}
      <Swiper
        slidesPerView={props.slidesPerView}
        spaceBetween={props.spaceBetween}
        navigation
        pagination={{ clickable: true }}
        style={{ padding: '50px 20px' }}
      >
        {props.companyData.map((company, index) => {
          return (
            <SwiperSlide key={index}>
              <Challenge
                company={company}
                setShowFullDetails={setShowFullDetails}
                setCurrentCompany={setCurrentCompany}
                setCurrentScreen={props.setCurrentScreen}
                setSelectedProgram={props.setSelectedProgram}
              />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};

export default ChallengeSwiper;
