import React, { useState } from 'react';
import { Link } from 'react-scroll';

import { GiHamburgerMenu } from 'react-icons/gi';

const mobileIiconStyle =
  'text-gray-300 sm:hidden text-3xl hover:text-primary cursor-pointer transition 300ms ease-in-out';

const Navbuttons = ({ selectedNav, setSelectedNav }) => {
  const navButtons = [
    'Home',
    'Recruiters',
    'Opportunities',
    'About',
    'Overview',
    'Domains',
    'Testimonials',
    'FAQs',
  ];

  const [showNav, setShowNow] = useState(false);

  return (
    <div className='sticky top-0 z-50'>
      <div className='flex bg-white justify-between mt-6 py-6 mx-2 sm:mx-6 xl:mx-10 '>
        <img
          src='https://res.cloudinary.com/belong/image/upload/v1638202033/uploaded_resources/Belong_logo_qiv6fv.png'
          alt='Logo'
          //className='h-12 md:h-16 object-cover m-2'
          className={showNav ? 'hidden' : 'h-12 md:h-14 object-cover my-2'}
        />
        <div className={showNav ? 'flex justify-between mt-2' : 'lg:mt-6'}>
          <div className={showNav ? '' : 'hidden md:block'}>
            <div className='grid grid-cols-8 rounded-xl lg:flex space-x-10'>
              {navButtons.map((button) => {
                return (
                  <button
                    key={button}
                    className={`${
                      selectedNav === button ? 'text-primary' : ''
                    } col-span-4 sm:col-span-2 lg:col-span-1 font-semibold  text-gray-500`}
                  >
                    <Link
                      activeClass='active'
                      to={button}
                      spy={true}
                      smooth={true}
                      offset={-90}
                      duration={500}
                      onSetActive={() => setSelectedNav(button)}
                    >
                      {button}
                    </Link>
                  </button>
                );
              })}
            </div>
          </div>
          <GiHamburgerMenu
            onClick={() => {
              setShowNow(!showNav);
            }}
            className={mobileIiconStyle}
          />
        </div>
      </div>
    </div>
  );
};

export default Navbuttons;
