import React, { useEffect } from 'react';
import axios from 'axios';
import { API_URL } from '../../../API_LINKS';

const Payment = (props) => {
  props.setShowPayment(false);
  const values = {
    amount: props.amount * 100,
    currency: props.currency,
  };
  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src;
      script.onload = () => {
        props.setPaymentLoading(false);
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    const res = await loadScript(
      'https://checkout.razorpay.com/v1/checkout.js'
    );

    if (!res) {
      alert('Razorpay failed to load. Are you online?');
      return;
    }

    const result = await axios.post(API_URL + '/order/create-order', values);

    if (!result) {
      alert('Server error. Are you online?');
      return;
    }

    const { amount, id: order_id, currency } = result.data;

    const options = {
      // rzp_live_swpmN4vqOWVnQz
      // rzp_test_pLQSbOLTeCmmpi
      key: 'rzp_live_swpmN4vqOWVnQz', // Enter the Key ID generated from the Dashboard
      amount: amount.toString(),
      currency: currency,
      name: 'Belong',
      order_id: order_id,
      handler: async function (response) {
        const data = {
          email: props.email,
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
          amount: amount.toString(),
          currency: currency,
          userId: props.userId || null, //If user already exists
          programId: props.checkedProgram,
          phone: props.phone,
          orderType: 'Careers Page',
          orderCollectionId: props.savedOrderId,
        };

        const result = await axios.post(API_URL + '/order/success', data);
        // if (result.status === 201) {
        //Enroll after successful payment and signup
        // props.enroll(props.userId, props.checkedProgram);
        // }

        //Payment will be made and user might or might not exist

        if (result.status === 201) {
          if (props.userExists) {
            props.setCurrentScreen('SUCCESS');
          } else {
            props.setCurrentScreen('SIGNUP');
          }
        }
      },
      prefill: {
        email: props.email,
        contact: props.phone,
      },
      readonly: {
        email: true,
        contact: true,
      },
      theme: {
        color: '#61dafb',
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  useEffect(() => {
    displayRazorpay();
  });

  return (
    <>
      <button className={props.buttonStyle} onClick={displayRazorpay}></button>
    </>
  );
};

export default Payment;
