import React from 'react';

const Opportunity = ({
  opportunity,
  setShowFullDetails,
  setCurrentOpportunity,
}) => {
  return (
    <div className='mt-10 relative border-2 shadow-md rounded-lg text-center'>
      <p className='flex flex-col justify-evenly text-primary font-semibold md:text-xl mt-4'>
        {' '}
        {opportunity.title}
      </p>
      <div className='m-4 font-semibold border-2 text-center bg-white rounded-lg flex flex-col justify-evenly h-12'>
        Full Time
      </div>
      <div className='grid grid-cols-2 gap-4 '>
        <div className='mx-6 md:mx-auto'>
          <div className='text-white bg-primary rounded-md py-3 font-bold w-32 cursor-pointer'>
            <a
              href={`https://dashboard.belong.education/jobs/${opportunity.slug}`}
              target='_blank'
              rel='noreferrer'
            >
              Apply Now
            </a>
          </div>
        </div>
        <a href={opportunity.link} className='mx-auto'>
          <img
            className='max-h-6 md:max-h-12	'
            src={opportunity.logo}
            alt='logo'
          />
        </a>
      </div>
      <div>
        {' '}
        <button
          onClick={() => {
            setCurrentOpportunity(opportunity);
            setShowFullDetails(true);
          }}
          className='underline font-semibold text-sm mt-3 mb-6 text-primary'
        >
          View More{' '}
        </button>
      </div>
    </div>
  );
};

export default Opportunity;
