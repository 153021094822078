import React from 'react';
import SignUpForm from './SignUpForm';

const Signup = ({ setPassword, setCurrentScreen, setName, name }) => {
  return (
    <div>
      <div className='flex justify-between p-8 border-b-2'>
        <div className='flex items-center text-gray-600'></div>
        <div className='text-xs text-gray-400'>
          <div>STEP 03/03</div>
          <div>Sign up to BELONG</div>
        </div>
      </div>
      <div>
        <SignUpForm
          setPassword={setPassword}
          setCurrentScreen={setCurrentScreen}
          setName={setName}
          name={name}
        />
      </div>
    </div>
  );
};

export default Signup;
